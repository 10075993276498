.fellow-overview .banner-head{
    color: var(--blue-3);
    /* font-size: 35px; */
    /* font-family: YACgEQNAr7w-0; */
    font-size: 32px;
    font-weight: 700;
    margin: 0;
    line-height: 36px;
}
.fellow-overview .banner-title{
    color: var(--blue-3);
    font-size: 18px;
    line-height: 25px;
}
.fellow-overview .page-title,
.fellow-overview .page-sub-title{
    text-transform: uppercase;
    margin: 0;
    /* font-family: "YACgES4gM44 0", _fb_, auto; */
}
.fellow-overview .page-title{
    font-size: 60px;
    color: var(--blue-3);
    font-weight: bold;
}
.fellow-overview .page-sub-title{
    font-size: 50px;
    color: var(--blue-4);
    /* font-weight: bold; */
}
/* .fellow-overview .page-sub-med-title{
    color: rgb(115, 115, 115);
    font-weight: bold;
} */
.c-box{
    text-transform: uppercase;
    font-size: 22px;
    border-radius: 10px;
    padding: 4px 3px;
    text-align: center;
    color: #fff;
    font-weight: bold;
}
.fellow-overview .box-blue1,
.fellow-overview .box-blue2,
.fellow-overview .box-grey,
.fellow-overview .box-grey2{
    font-size: 20px;
    color: #fff;
    padding: 12px 10px;
    /* border-radius: 5px; */
    border-radius: 8px;
    font-weight: bold;
}
.fellow-overview .fellow-f-btn .box-blue1,
.fellow-overview .fellow-f-btn .box-blue2,
.fellow-overview .fellow-f-btn .box-grey{
    padding: 20px 10px;
}
.fellow-overview .box-blue1{
    background-color: var(--blue-3);
}
.fellow-overview .box-blue2{
    background-color: var(--blue-4);
}
.fellow-overview .box-grey{
    background-color: #4e5a5b;
}
.fellow-overview .box-grey2{
    background-color: #a6a6a6;
}
.fellow-feature img{
    /* border-radius: 5px; */
    border-radius: 20px;
    margin-bottom: 8px;
    /* height: 185px;
    width: 100%; */
}
.fellow-feature .f-title{
    text-align: center;
    font-size: 15px;
    /* margin-bottom: 0px; */
    margin-bottom: 16px;
}
/* Campaign Page */
.fellow-campaign .page-title,
.fellow-campaign .page-med-title,
.fellow-campaign .page-sub-title{
    text-shadow: rgba(0, 0, 0, 0.325) 0px 4.91989px 20.9095px;
    letter-spacing: 0.02em;
    font-weight: bold;
}
.fellow-campaign .page-sub-title{
    /* font-size: 5.4vw !important; */
    font-size: 5.1vw !important;
    /* line-height: 95px; */
    line-height: calc(5.1vw + 4px) !important;
}
.fellow-campaign .page-sub-med-title{
    font-size: 3.2vw !important;
    line-height: calc(3.2vw + 4px) !important;
    /* text-shadow: rgba(0, 86, 171, 0.4) 4.03073px 4.03073px 0px; */
}
.fellow-campaign .page-title{
    /* font-size: 8vw !important; */
    font-size: 7.6vw !important;
    line-height: calc(7.6vw + 7px) !important;
}
.fellow-campaign .page-med-title{
    font-size: 4.6vw !important;
    line-height: calc(4.6vw + 7px) !important;
    /* text-shadow: rgba(0, 86, 171, 0.4) 4.03073px 4.03073px 0px; */
}
.fellow-campaign .box-camp-1{
    /* font-size: 2.76vw !important; */
    /* font-size: 2.72vw !important; */
    font-size: 2.6vw !important;
    text-transform: none;
    letter-spacing: 0.12em !important;
    /* line-height: 48px; */
    line-height: calc(2.76vw + 7px) !important;
    padding: 10px 10px;
}
.fellow-campaign .compact{
    padding-right: 30px;
    padding-left: 30px;
}
.fellow-campaign .box-purple{
    font-size: 50.6659px;
    color: rgb(255, 255, 255);
    /* line-height: 70px; */
    letter-spacing: 0.12em;
    font-weight: bold;
    padding: 10px 10px 0px;
    background: rgb(132, 12, 157);
    border-radius: 5px;
}
.fellow-campaign .box-purple img{
    height: 64px;
    width: 80px;
    margin-bottom: 10px;
}
.fellow-campaign .card{
    /* background: url(https://ifspd.eu/static/media/bg-fellowship.50c754a68aa8bc64987d.png) 50% no-repeat; */
    background-size: cover !important;
    overflow: hidden;
    position: relative;
    border: none;
    border-radius: 0px;
}
.fellow-campaign .box-purple{
    margin-bottom: 40px;
}
.fellow-campaign img{
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
}
.fellow-campaign .f-title{
    color: var(--theme-primary-blue);
    /* font-weight: 700; */
    font-weight: bold;
}
.fellow-campaign .heading{
    /* font-weight: 400; */
    color: var(--theme-primary-blue);
    letter-spacing: 0.02em;
    font-size: 32px;
    font-weight: bold;
    padding: 0px;
    text-transform: uppercase;
}
.fellow-campaign .plan-table table{
    border: none;
    margin-bottom: 0px;
}
.fellow-campaign .plan-table tbody td{
    border: 2px solid #000000;
}
.fellow-campaign .plan-table p{
    margin-bottom: 0px;
}
.fellow-campaign .plan-table thead th p{
    /* width: fit-content; */
    margin-bottom: 0px;
    width: 165px;
    color: #fff;
    text-transform: uppercase;
    font-size: 22px;
    border-radius: 10px;
    padding: 4px 3px;
    text-align: center;
}
.fellow-campaign .plan-table .t-title,
.program-sec .content .t-title{
    letter-spacing: 0.12em;
    /* color: var(--theme-primary-blue); */
    color: #0056ab;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 22px;
}
.fellow-campaign .plan-table thead th{
    padding-bottom: 0px;
}
.program-sec .content p,
.week-btn-sec p{
    margin-bottom: 0px;
}
.fellow-campaign .plan-table tbody .content,
.program-sec .content .t-desc{
    font-size: 21px;
    font-weight: 700;
    vertical-align: top;
    letter-spacing: 0.12em;
}
.program-sec .content .t-desc{
    font-size: 18px;
}
.fellow-campaign .plan-table tbody ol{
    margin-left: 30px;
}
/* .fellow-campaign .plan-table ol {
    counter-reset: my-badass-counter;
}
.fellow-campaign .plan-table ol li:before {
    content: counter(my-badass-counter, upper-alpha);
    counter-increment: my-badass-counter;
    margin-right: 5px;
    font-weight: normal;
} */
.fellow-campaign .plan-table ol li::marker {
    /* content: counter(my-badass-counter, upper-alpha);
    counter-increment: my-badass-counter;
    margin-right: 5px; */
    font-weight: normal;
}
.fellow-campaign .plan-table tbody ol li{
    list-style: number;
}
.fellow-campaign .program-sec .content{
    background: #f7f7f7;
    border-radius: 5px;
    padding: 20px 15px;
}
.fellow-campaign .program-sec img.top,
.fellow-campaign .program-sec img.bottom{
    height: 200px;
    padding: 0px;
}
/* .fellow-campaign .program-sec img.top{
    height: 200px;
    padding: 0px;
}
.fellow-campaign .program-sec img.bottom{
    height: 180px;
    padding: 0px;
} */
.alumni-sec img{
    height: 250px;
    width: 250px;
}
.alumni-sec p{
    font-size: 20px;
    margin-bottom: 0px;
}
.alumni-sec .t-name{
    font-weight: bold;
}
.alumni-sec .t-name,
.alumni-sec .t-country{
    text-transform: uppercase;
}
.fellow-campaign .tick-icon{
    position: absolute;
    right: 0;
    bottom: 0;
}
.fellow-campaign .tick-icon img{
    height: 75px;
    width: 75px;
}
.fellow-campaign .strike-price{
    text-decoration: line-through;
    color: red;
}
/* .fellow-sus:before{
    content: "";
    background: rgba(6,12,34,.45);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
} */
@media (min-width: 575px) and (max-width: 850px) {
    .fellow-overview .box-blue1,
    .fellow-overview .box-blue2,
    .fellow-overview .box-grey,
    .fellow-overview .box-grey2{
        font-size: 15px;
    }
}
@media only screen and (max-width: 774px){
    .fellow-overview .page-title{
        font-size: 40px;
    }
    .fellow-overview .page-sub-title{
        font-size: 30px;
    }
    .fellow-campaign .page-med-title,
    .fellow-campaign .page-sub-med-title{
        text-shadow: none;
    }
}
@media only screen and (max-width: 576px){
    .fellow-overview .card-img-overlay{
        position: unset;
        border: none;
    }
    .fellow-overview .card{
        border: none;
    }
    .fellow-overview .header-image{
        width: 140px;
    }
    .fellow-overview .banner-head{
        font-size: 18px;
    }
    .fellow-overview .banner-title{
        font-size: 10px;
    }
    .fellow-campaign .box-purple,
    .heading-grey{
        font-size: 25px !important;
    }
    .heading-grey{
        margin-top: 20px;
        padding-bottom: 12px;
    }
    .sus-heading-grey{
        font-size: 4.9vw !important;
        line-height: calc(4.9vw + 4px);
    }
    .fellow-campaign .box-purple img{
        height: 30px;
        width: 35px;
    }
    .fellow-campaign .compact{
        padding-right: 5px;
        padding-left: 5px;
    }
    .fellow-campaign .box-camp-1{
        font-size: 12px !important;
    }
    .fellow-campaign .heading{
        font-size: 20px;
        margin-top: 10px;
    }
}
@media only screen and (max-width: 915px){
    .fellow-overview .fellow-f-btn .box-blue1,
    .fellow-overview .fellow-f-btn .box-blue2,
    .fellow-overview .fellow-f-btn .box-grey{
        padding: 12px 10px;
    }
}
@media only screen and (max-width: 994px){
    .fellow-campaign .tick-icon{
        display: none;
    }
}
@media (min-width: 994px) and (max-width: 1399px) {
    .fellow-campaign .tick-icon img{
        height: 95px !important;
        width: 85px !important;
    }
}