#pgc-57-1-1, #pgc-57-3-1 {
    width: 85%;
    width: calc(85% - (0.15 * 30px));
}
@media (max-width: 960px){
    #pg-57-0>.panel-grid-cell, #pg-57-0>.panel-row-style>.panel-grid-cell, #pg-57-1>.panel-grid-cell, #pg-57-1>.panel-row-style>.panel-grid-cell, #pg-57-2>.panel-grid-cell, #pg-57-2>.panel-row-style>.panel-grid-cell, #pg-57-3>.panel-grid-cell, #pg-57-3>.panel-row-style>.panel-grid-cell,
    #pg-1595-0>.panel-grid-cell, #pg-1595-0>.panel-row-style>.panel-grid-cell, #pg-1595-1>.panel-grid-cell, #pg-1595-1>.panel-row-style>.panel-grid-cell, #pg-1595-2>.panel-grid-cell, #pg-1595-2>.panel-row-style>.panel-grid-cell, #pg-1595-3>.panel-grid-cell, #pg-1595-3>.panel-row-style>.panel-grid-cell, #pg-1595-4>.panel-grid-cell, #pg-1595-4>.panel-row-style>.panel-grid-cell, #pg-1595-5>.panel-grid-cell, #pg-1595-5>.panel-row-style>.panel-grid-cell, #pg-1595-6>.panel-grid-cell, #pg-1595-6>.panel-row-style>.panel-grid-cell, #pg-1595-7>.panel-grid-cell, #pg-1595-7>.panel-row-style>.panel-grid-cell, #pg-1595-8>.panel-grid-cell, #pg-1595-8>.panel-row-style>.panel-grid-cell, #pg-1595-9>.panel-grid-cell, #pg-1595-9>.panel-row-style>.panel-grid-cell, #pg-1595-10>.panel-grid-cell, #pg-1595-10>.panel-row-style>.panel-grid-cell, #pg-1595-11>.panel-grid-cell, #pg-1595-11>.panel-row-style>.panel-grid-cell, #pg-1595-12>.panel-grid-cell, #pg-1595-12>.panel-row-style>.panel-grid-cell, #pg-1595-13>.panel-grid-cell, #pg-1595-13>.panel-row-style>.panel-grid-cell, #pg-1595-14>.panel-grid-cell, #pg-1595-14>.panel-row-style>.panel-grid-cell, #pg-1595-15>.panel-grid-cell, #pg-1595-15>.panel-row-style>.panel-grid-cell, #pg-1595-16>.panel-grid-cell, #pg-1595-16>.panel-row-style>.panel-grid-cell, #pg-1595-17>.panel-grid-cell, #pg-1595-17>.panel-row-style>.panel-grid-cell, #pg-1595-18>.panel-grid-cell, #pg-1595-18>.panel-row-style>.panel-grid-cell, #pg-1595-19>.panel-grid-cell, #pg-1595-19>.panel-row-style>.panel-grid-cell,
    #pg-2789-0>.panel-grid-cell, #pg-2789-0>.panel-row-style>.panel-grid-cell, #pg-2789-1>.panel-grid-cell, #pg-2789-1>.panel-row-style>.panel-grid-cell {
        width: 100%;
        margin-right: 0;
    }
    #pl-57 .panel-grid .panel-grid-cell-empty {
        display: none;
    }
    #pg-57-0.panel-no-style, #pg-57-0.panel-has-style>.panel-row-style, #pg-57-0, #pg-57-1.panel-no-style, #pg-57-1.panel-has-style>.panel-row-style, #pg-57-1, #pg-57-2.panel-no-style, #pg-57-2.panel-has-style>.panel-row-style, #pg-57-2, #pg-57-3.panel-no-style, #pg-57-3.panel-has-style>.panel-row-style, #pg-57-3,
    #pg-1595-0.panel-no-style, #pg-1595-0.panel-has-style>.panel-row-style, #pg-1595-0, #pg-1595-1.panel-no-style, #pg-1595-1.panel-has-style>.panel-row-style, #pg-1595-1, #pg-1595-2.panel-no-style, #pg-1595-2.panel-has-style>.panel-row-style, #pg-1595-2, #pg-1595-3.panel-no-style, #pg-1595-3.panel-has-style>.panel-row-style, #pg-1595-3, #pg-1595-4.panel-no-style, #pg-1595-4.panel-has-style>.panel-row-style, #pg-1595-4, #pg-1595-5.panel-no-style, #pg-1595-5.panel-has-style>.panel-row-style, #pg-1595-5, #pg-1595-6.panel-no-style, #pg-1595-6.panel-has-style>.panel-row-style, #pg-1595-6, #pg-1595-7.panel-no-style, #pg-1595-7.panel-has-style>.panel-row-style, #pg-1595-7, #pg-1595-8.panel-no-style, #pg-1595-8.panel-has-style>.panel-row-style, #pg-1595-8, #pg-1595-9.panel-no-style, #pg-1595-9.panel-has-style>.panel-row-style, #pg-1595-9, #pg-1595-10.panel-no-style, #pg-1595-10.panel-has-style>.panel-row-style, #pg-1595-10, #pg-1595-11.panel-no-style, #pg-1595-11.panel-has-style>.panel-row-style, #pg-1595-11, #pg-1595-12.panel-no-style, #pg-1595-12.panel-has-style>.panel-row-style, #pg-1595-12, #pg-1595-13.panel-no-style, #pg-1595-13.panel-has-style>.panel-row-style, #pg-1595-13, #pg-1595-14.panel-no-style, #pg-1595-14.panel-has-style>.panel-row-style, #pg-1595-14, #pg-1595-15.panel-no-style, #pg-1595-15.panel-has-style>.panel-row-style, #pg-1595-15, #pg-1595-16.panel-no-style, #pg-1595-16.panel-has-style>.panel-row-style, #pg-1595-16, #pg-1595-17.panel-no-style, #pg-1595-17.panel-has-style>.panel-row-style, #pg-1595-17, #pg-1595-18.panel-no-style, #pg-1595-18.panel-has-style>.panel-row-style, #pg-1595-18, #pg-1595-19.panel-no-style, #pg-1595-19.panel-has-style>.panel-row-style, #pg-1595-19,
    #pg-2789-0.panel-no-style, #pg-2789-0.panel-has-style>.panel-row-style, #pg-2789-0, #pg-2789-1.panel-no-style, #pg-2789-1.panel-has-style>.panel-row-style, #pg-2789-1 {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    #pg-1491-0>.panel-grid-cell, #pg-1491-0>.panel-row-style>.panel-grid-cell, #pg-1491-1>.panel-grid-cell, #pg-1491-1>.panel-row-style>.panel-grid-cell, #pg-1491-2>.panel-grid-cell, #pg-1491-2>.panel-row-style>.panel-grid-cell, #pg-1491-3>.panel-grid-cell, #pg-1491-3>.panel-row-style>.panel-grid-cell {
        width: 100%;
        margin-right: 0;
    }
    #pg-1491-0.panel-no-style, #pg-1491-0.panel-has-style>.panel-row-style, #pg-1491-0, #pg-1491-1.panel-no-style, #pg-1491-1.panel-has-style>.panel-row-style, #pg-1491-1, #pg-1491-2.panel-no-style, #pg-1491-2.panel-has-style>.panel-row-style, #pg-1491-2, #pg-1491-3.panel-no-style, #pg-1491-3.panel-has-style>.panel-row-style, #pg-1491-3,
    #pg-w61e55944b01d7-0.panel-no-style, #pg-w61e55944b01d7-0.panel-has-style>.panel-row-style, #pg-w61e55944b01d7-0,
    #pg-w616fde0090cdd-0.panel-no-style, #pg-w616fde0090cdd-0.panel-has-style>.panel-row-style, #pg-w616fde0090cdd-0,
    #pg-w616fdebbda751-0.panel-no-style, #pg-w616fdebbda751-0.panel-has-style>.panel-row-style, #pg-w616fdebbda751-0,
    #pg-w5f3272345bad5-0.panel-no-style, #pg-w5f3272345bad5-0.panel-has-style>.panel-row-style, #pg-w5f3272345bad5-0,
    #pg-w5f3272345c136-0.panel-no-style, #pg-w5f3272345c136-0.panel-has-style>.panel-row-style, #pg-w5f3272345c136-0,
    #pg-1591-0.panel-no-style, #pg-1591-0.panel-has-style>.panel-row-style, #pg-1591-0, #pg-1591-1.panel-no-style, #pg-1591-1.panel-has-style>.panel-row-style, #pg-1591-1
    {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    /* committee */
    #pg-w616fde0090cdd-0>.panel-grid-cell, #pg-w616fde0090cdd-0>.panel-row-style>.panel-grid-cell,
    #pg-1533-0>.panel-grid-cell, #pg-1533-0>.panel-row-style>.panel-grid-cell, #pg-1533-1>.panel-grid-cell, #pg-1533-1>.panel-row-style>.panel-grid-cell, #pg-1533-2>.panel-grid-cell, #pg-1533-2>.panel-row-style>.panel-grid-cell, #pg-1533-3>.panel-grid-cell, #pg-1533-3>.panel-row-style>.panel-grid-cell, #pg-1533-4>.panel-grid-cell, #pg-1533-4>.panel-row-style>.panel-grid-cell, #pg-1533-5>.panel-grid-cell, #pg-1533-5>.panel-row-style>.panel-grid-cell,
    #pg-w61e55944b01d7-0>.panel-grid-cell, #pg-w61e55944b01d7-0>.panel-row-style>.panel-grid-cell,
    #pg-w616fdebbda751-0>.panel-grid-cell, #pg-w616fdebbda751-0>.panel-row-style>.panel-grid-cell,
    #pg-w5f3272345bad5-0>.panel-grid-cell, #pg-w5f3272345bad5-0>.panel-row-style>.panel-grid-cell,
    #pg-w5f3272345c136-0>.panel-grid-cell, #pg-w5f3272345c136-0>.panel-row-style>.panel-grid-cell,
    #pg-1591-0>.panel-grid-cell, #pg-1591-0>.panel-row-style>.panel-grid-cell, #pg-1591-1>.panel-grid-cell, #pg-1591-1>.panel-row-style>.panel-grid-cell{
        width: 100%;
        margin-right: 0;
        /* margin: 0px auto; */
    }
    #pg-1595-4>.panel-row-style, #pg-1595-6>.panel-row-style, #pg-1595-8>.panel-row-style, #pg-1595-10>.panel-row-style, #pg-1595-12>.panel-row-style, #pg-1595-14>.panel-row-style,
    #pl-1595 #panel-1595-16-0-0,
    #pgc-1595-18-0 {
        padding: 0px 0px 0px 0px !important;
    }
}
#pl-1491 .so-panel:last-of-type,
#pl-1595 .so-panel:last-of-type {
    margin-bottom: 0px;
}
#pg-1491-1>.panel-row-style, #pg-1491-3>.panel-row-style,
#pg-1591-1>.panel-row-style,
#pg-2789-1>.panel-row-style {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}
#pg-1491-1, #pg-1491-3,
#pg-1595-2, #pg-1595-5, #pg-1595-7, #pg-1595-9, #pg-1595-11, #pg-1595-13, #pg-1595-15, #pg-1595-17, #pg-1595-19, #pgc-1595-16-0, #pgc-1595-18-0 {
    /* margin-bottom: 60px; */
    margin-bottom: 40px;
}
#pg-1491-0, #pg-1491-2, #pl-1491 .so-panel,
#pg-1591-0, #pl-1591 .so-panel,
#pg-1595-0, #pg-1595-3, #pl-1595 .so-panel,
#pg-2789-0, #pl-2789 .so-panel{
    margin-bottom: 30px;
}
#pgc-1491-1-0, #pgc-1491-1-1,
#pgc-1491-3-0, #pgc-1491-3-1,
#pgc-1591-1-0, #pgc-1591-1-1,
#pgc-1595-2-0, #pgc-1595-2-1, #pgc-1595-5-0, #pgc-1595-5-1, #pgc-1595-7-0, #pgc-1595-7-1, #pgc-1595-9-0, #pgc-1595-9-1, #pgc-1595-11-0, #pgc-1595-11-1, #pgc-1595-13-0, #pgc-1595-13-1, #pgc-1595-15-0, #pgc-1595-15-1, #pgc-1595-17-0, #pgc-1595-17-1, #pgc-1595-19-0, #pgc-1595-19-1,
#pgc-2789-1-0, #pgc-2789-1-1{
    width: 50%;
    width: calc(50% - (0.5 * 80px));
}
/* principle */
#pg-1504-0.panel-has-style>.panel-row-style, #pg-1504-0.panel-no-style, #pg-1504-1.panel-has-style>.panel-row-style, #pg-1504-1.panel-no-style, #pg-1504-2.panel-has-style>.panel-row-style, #pg-1504-2.panel-no-style, #pg-1504-3.panel-has-style>.panel-row-style, #pg-1504-3.panel-no-style, #pg-1504-4.panel-has-style>.panel-row-style, #pg-1504-4.panel-no-style, #pg-1504-5.panel-has-style>.panel-row-style, #pg-1504-5.panel-no-style, #pg-1504-6.panel-has-style>.panel-row-style, #pg-1504-6.panel-no-style, #pg-1504-7.panel-has-style>.panel-row-style, #pg-1504-7.panel-no-style, #pg-1504-8.panel-has-style>.panel-row-style, #pg-1504-8.panel-no-style, #pg-1504-9.panel-has-style>.panel-row-style, #pg-1504-9.panel-no-style, #pg-1504-10.panel-has-style>.panel-row-style, #pg-1504-10.panel-no-style, #pg-1504-11.panel-has-style>.panel-row-style, #pg-1504-11.panel-no-style, #pg-1504-12.panel-has-style>.panel-row-style, #pg-1504-12.panel-no-style, #pg-1504-13.panel-has-style>.panel-row-style, #pg-1504-13.panel-no-style, #pg-1504-14.panel-has-style>.panel-row-style, #pg-1504-14.panel-no-style {
    -webkit-align-items: flex-start;
    align-items: flex-start;
}
#pg-1504-1>.panel-row-style, #pg-1504-2>.panel-row-style, #pg-1504-3>.panel-row-style, #pg-1504-4>.panel-row-style, #pg-1504-5>.panel-row-style, #pg-1504-6>.panel-row-style, #pg-1504-7>.panel-row-style, #pg-1504-8>.panel-row-style, #pg-1504-9>.panel-row-style, #pg-1504-10>.panel-row-style, #pg-1504-11>.panel-row-style, #pg-1504-12>.panel-row-style, #pg-1504-13>.panel-row-style, #pg-1504-14>.panel-row-style,
#pg-1595-2>.panel-row-style, #pg-1595-5>.panel-row-style, #pg-1595-7>.panel-row-style, #pg-1595-9>.panel-row-style, #pg-1595-11>.panel-row-style, #pg-1595-13>.panel-row-style, #pg-1595-15>.panel-row-style, #pg-1595-17>.panel-row-style, #pg-1595-19>.panel-row-style,
#pg-1595-2>.panel-row-style, #pg-1595-5>.panel-row-style, #pg-1595-7>.panel-row-style, #pg-1595-9>.panel-row-style, #pg-1595-11>.panel-row-style, #pg-1595-13>.panel-row-style, #pg-1595-15>.panel-row-style, #pg-1595-17>.panel-row-style, #pg-1595-19>.panel-row-style {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}
#pg-1504-1, #pg-1504-2, #pg-1504-3, #pg-1504-4, #pg-1504-5, #pg-1504-6, #pg-1504-7, #pg-1504-8, #pg-1504-9, #pg-1504-10, #pg-1504-11, #pg-1504-12, #pg-1504-13, #pg-1504-14,
#pg-1595-1 {
    margin-bottom: 20px;
}
#pgc-1504-1-1, #pgc-1504-2-1, #pgc-1504-3-1, #pgc-1504-4-1, #pgc-1504-5-1, #pgc-1504-6-1, #pgc-1504-7-1, #pgc-1504-8-1, #pgc-1504-9-1, #pgc-1504-10-1, #pgc-1504-11-1, #pgc-1504-12-1, #pgc-1504-13-1, #pgc-1504-14-1 {
    width: 92%;
    width: calc(92% - (0.08 * 30px));
}
/* committee */
#pl-w61e55944b01d7 #panel-w61e55944b01d7-0-0-0, #pl-w61e55944b01d7 #panel-w61e55944b01d7-0-1-0, #pl-w61e55944b01d7 #panel-w61e55944b01d7-0-1-1 {
    margin: 0px 0px 0px 0px;
}
#pl-1533 #panel-1533-1-0-0, #pl-1533 #panel-1533-1-0-1, #pl-1533 #panel-1533-2-0-0, #pl-1533 #panel-1533-2-0-1, #pl-1533 #panel-1533-3-0-0, #pl-1533 #panel-1533-3-0-1, #pl-1533 #panel-1533-4-0-0, #pl-1533 #panel-1533-4-0-1, #pl-1533 #panel-1533-5-0-0, #pl-1533 #panel-1533-5-0-1 {
    margin: 0px 0px 0px 0px;
}
#pg-1533-1, #pg-1533-2, #pg-1533-3, #pg-1533-4, #pg-1533-5 {
    margin-bottom: 50px;
}
#pg-1533-0, #pl-1533 .so-panel {
    margin-bottom: 30px;
}
#pl-1533 .so-panel:last-of-type,
#pl-1591 .so-panel:last-of-type,
#pl-2789 .so-panel:last-of-type {
    margin-bottom: 0px;
}
.ifpresidents-page .ifpresidents-photo {
    text-align: left;
    padding-top: 30px;
    padding-left: 30px;
}
#pgc-w61e55944b01d7-0-1,
#pgc-w616fde0090cdd-0-1,
#pgc-w616fdebbda751-0-1,
#pgc-w5f3272345bad5-0-1,
#pgc-w5f3272345c136-0-1{
    width: 75%;
    width: calc(75% - (0.25 * 30px));
}
#pgc-w616fdebbda751-0-0,
#pgc-w5f3272345bad5-0-0,
#pgc-w5f3272345c136-0-0 {
    width: 25%;
    width: calc(25% - (0.75 * 30px));
}
#pg-1595-4>.panel-row-style, #pg-1595-6>.panel-row-style, #pg-1595-8>.panel-row-style, #pg-1595-10>.panel-row-style, #pg-1595-12>.panel-row-style, #pg-1595-14>.panel-row-style,
#panel-1595-16-0-0, #pgc-1595-18-0{
    padding: 0px 0px 0px 60px;
}
#pl-1595 #panel-1595-1-0-0, #pl-1595 #panel-1595-3-0-0, #pl-1595 #panel-1595-8-0-0, #pl-1595 #panel-1595-10-0-0, #pl-1595 #panel-1595-12-0-0, #pl-1595 #panel-1595-14-0-0, #pl-1595 #panel-1595-16-0-0, #pl-1595 #panel-1595-18-0-0{
    margin: 0px 0px 10px 0px;
}
#pl-1595 #panel-1595-4-0-0,
#pl-1595 #panel-1595-6-0-0,
#pl-1595 #panel-1595-8-0-0,
#pl-1595 #panel-1595-10-0-0,
#pl-1595 #panel-1595-12-0-0,
#pl-1595 #panel-1595-14-0-0{
    margin: 0px 0px 30px 0px;
}