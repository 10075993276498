.hub-content{
    display: flex;
}
.hub-content .carousel-container {
    width: 100%;
    position: unset !important;
}
.hub-content .carousel-container ul li .caro-content,
.hub-list .hub-lst-content{
    /* background-color: red; */
    padding: 10px;
    margin-right: 6px;
    margin-left: 6px;
    /* width: 250px; */
    border-radius: 18px;
}
.hub-content .carousel-container ul li p{
    margin-bottom: 5px;
}
.hub-content .heading,
.hub-list .heading{
    font-size: 20px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    min-height: 56px;
    line-height: 28px;
    -webkit-line-clamp: 2;
    /* width: 250px; */
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-bottom: 10px !important;
}
.hub-content .sub-content,
.hub-list .sub-content{
    -webkit-line-clamp: 1;
    /* width: 250px; */
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-bottom: 10px !important;
}
.hub-content .carousel-container ul li:nth-of-type(4n + 1) .caro-content,
.hub-list div:nth-of-type(4n + 1) .hub-lst-content{
    background-color: #203864;
    color: white;
}
.hub-content .carousel-container ul li:nth-of-type(4n + 2) .caro-content,
.hub-list div:nth-of-type(4n + 2) .hub-lst-content{
    background-color:#2f5597;
    color: white;
}
.hub-content .carousel-container ul li:nth-of-type(4n + 3) .caro-content,
.hub-list div:nth-of-type(4n + 3) .hub-lst-content{
    background-color: #8faadc;
    /* background-color: #5e7eb5; */
}
.hub-content .carousel-container ul li:nth-of-type(4n + 4) .caro-content,
.hub-list div:nth-of-type(4n + 4) .hub-lst-content{
    background-color: #b4c7e7;
    /* background-color: #8faadc; */
    /* background-color: #84a3de; */
}
/* .hub-content .carousel-container ul li:last {
    background-color: white;
} */
.hub-content .carousel-container ul li:nth-of-type(4n + 1) a,
.hub-content .carousel-container ul li:nth-of-type(4n + 2) a,
.hub-list div:nth-of-type(4n + 1) .hub-lst-content a,
.hub-list div:nth-of-type(4n + 2) .hub-lst-content a
{
    color: white;
}
.hub-content .carousel-container ul li:nth-of-type(4n + 3) a,
.hub-content .carousel-container ul li:nth-of-type(4n + 4) a,
.hub-list div:nth-of-type(4n + 3) .hub-lst-content a,
.hub-list div:nth-of-type(4n + 4) .hub-lst-content a{
    color: black;
}
.hub-content .carousel-container ul li .img-logo,
.hub-list .img-logo{
    max-width: 58px;
    max-height: 58px;
    background: white;
    border-radius: 50%
}
.hub-content .carousel-container ul li .img-logo img,
.hub-list .img-logo img{
    border-radius: 50%;
    padding: 2px;
}
.hub-logo{
    /* width: 150px !important; */
    /* height: 100px !important; */
    min-width: 115px;
}
.hub-logo img{
    border-radius: 12px;
}
.round-box{
    border-radius: 6px;
    background: aliceblue;
    padding: 5px 8px;
    font-size: 13px;
    margin-right: 8px;
    margin-top: 4px;
    width: fit-content;
    margin-bottom: 4px;
}
.same-row .round-box:nth-of-type(3n + 1) {
    background-color: #e2f0fc;
}
.same-row .round-box:nth-of-type(3n + 2) {
    background-color:#c9e5fd;
}
.same-row .round-box:nth-of-type(3n + 3) {
    background-color: #cbe1f4;
}
.hub-modal .card{
    /* border-color: #fff; */
    padding: 8px;
    margin-bottom: 12px;
}
.hub-modal .modal-body{
    height: 75vh;
    overflow-y: scroll;
}