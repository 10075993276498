.fellow-cert .head{
    color: var(--theme-purple);
    font-size: 62px;
    text-transform: none;
    letter-spacing: -0.02em;
    margin-bottom: 15px;
}
.fellow-cert .title-head{
    color: var(--theme-purple);
    font-size: 48px;
    text-transform: none;
    letter-spacing: -0.02em;
    margin-bottom: 15px;
    font-weight: 700;
}
.fellow-cert .banner ul{
    margin-left: 39px;
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
}
.fellow-cert .banner ul li{
    list-style: disc;
}
.fellow-cert .banner ul li.highlight{
    font-weight: 700;
    color: var(--theme-purple);  
}
.fellow-cert .banner .box-purple{
    width: fit-content;
    padding: 6px 40px 14px;
    font-size: 34px;
    /* color: rgb(255, 255, 255); */
    line-height: 45px;
    letter-spacing: 0.1em;
    margin-bottom: 20px;
}
.fellow-cert img.student{
    padding-right: 0px;
    padding-left: 0px;
}
.fellow-cert .f-title{
    font-size: 22px;
    color: var(--theme-purple);
}
.fellow-cert .box{
    margin-right: 8px;
    margin-left: 8px;
    font-size: 19px;
    color: #fff;
    padding: 12px 10px;
    border-radius: 8px;
    font-weight: bold;
}
.fellow-cert .cert-feature h4{
    color: #fff;
    font-size: 28px;
    font-weight: 700;
}
.fellow-cert .cert-feature p{
    font-size: 21px;
    font-weight: 400;
    color: #fff;
}
.fellow-cert .cert-benefit .card{
    border-radius: 20px;
}
.fellow-cert .cert-benefit img{
    border-radius: 8px;
    padding: 0px;
}
.fellow-cert .cert-benefit p{
    font-weight: 700;
    font-size: 18px;
}
.fellow-cert img.student{
    height: -webkit-fill-available;
    height: 100%;
}
.fellow-cert .cert-benefit img{
    height: 100px;
    width: 100px;
}
.fellow-cert .leader-sec .t-position{
    font-size: 18px;
}
.fellow-cert .leader-sec .t-name{
    padding-top: 10px;
    font-size: 20px;
    text-transform: none;
}
.fellow-cert .whatsapp{
    display: flex;
    align-items: center;
}
.fellow-cert .fee-structure h5{
    color: var(--theme-purple);
    font-weight: 700;
    font-size: 32px;
}
.fellow-cert .fee-structure .fee{
    width: 155px;
}
.fellow-cert .fee-structure p{
    font-size: 23.3331px;
}
@media only screen and (max-width: 774px){
    .fellow-cert .head{
        font-size: 35px;
        text-align: center;
    }
    .fellow-cert .title-head{
        font-size: 35px;
        text-align: center;
    }
    .header-image{
        width: 200px !important;
        /* text-align: center; */
    }
    .fellow-cert .banner ul {
        font-size: 20px;
    }
    .flex-middle{
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .fellow-cert .banner .box-purple{
        font-size: 20px !important;
        padding: 6px 20px 14px;
    }
    /* .fellow-cert img.student{
        height: 50%;
        width: 50%;
    } */
}
@media (min-width: 775px) and (max-width: 915px) {
    .fellow-cert .head{
        font-size: 42px;
    }
}
@media (min-width: 775px) and (max-width: 1050px) {
    .fellow-cert .box{
        font-size: 15px;
    }
}