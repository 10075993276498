.ev-chat{
    z-index: 99999;
    position: relative;
}
.ev-chat .bot-toggler-mob,
.ev-chat .bot-toggler{
  position: fixed;
}
.ev-chat .bot-toggler {
  bottom: 30px;
  right: 35px;
  border: none;
  height: 85px;
  width: 85px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--theme-blue);
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); */
  transition: all 0.2s ease;
  box-shadow: 0 2px 10px 1px #b5b5b5;
  padding: 30px;
  /* display: inline-block; */
  animation: botBtnShake 2s infinite;
}
.ev-chat .bot-toggler-mob{
  padding: 0px;
  top: 50%;
  right: -27px;
  transform: rotate(-90deg);
  background: transparent;
}
.ev-chat .bot-toggler:hover {
    animation: none;
}
.ev-chat .bot-container.show-bot .bot-toggler {
  transform: rotate(90deg);
}
.ev-chat .bot-toggler span {
  color: #fff;
  position: absolute;
}
.ev-chat .bot-toggler video{
  border-radius: 50%;
}
.ev-chat .bot-toggler span:last-child,
.ev-chat .bot-container.show-bot .bot-toggler span:first-child {
  opacity: 0;
}

.ev-chat .bot-container.show-bot .bot-toggler span:last-child {
  opacity: 1;
}
.ev-chat .unread-badge{
    background-color: red;
    border-radius: 50%;
    color: #fff;
    height: 25px;
    line-height: 25px;
    position: fixed;
    right: -5px;
    text-align: center;
    top: -5px;
    width: 25px;
    z-index: 1;
}
.ev-chat .ev-label{
  /* background: #ede6f1; */
  /* background: #a2cfc7; */
  background: #f4f7f9;
  font-size: 12px;
  /* bottom: -16px; */
  bottom: -12px;
  display: flex;
  /* width: 79px; */
  width: 56px;
  padding: 3px 5px;
  border-radius: 3px;
  color: #0353aa !important;
  /* color: white; */
}
.ev-chat .ev-label img{
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 5px;
}
.ev-chat .bot-popup {
  position: fixed;
  width: 375px;
  min-width: 375px;
  max-width: 100%;
  opacity: 0;
  /* right: 35px; */
  /* bottom: 90px; */
  right: 10px;
  bottom: 10px;
  pointer-events: none;
  transform: scale(0.2);
  overflow: hidden;
  background: #fff;
  border-radius: 10px;
  transform-origin: bottom right;
  /* box-shadow: 0 0 128px 0 rgba(16, 15, 15, 0.1),
    0 32px 64px -48px rgba(0, 0, 0, 0.5); */
    box-shadow: 0 2px 10px 1px #b5b5b5;
  /* transition: all 0.1s ease; */
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;   
  z-index: 9;
}
.ev-chat .bot-popup.fullscreen{
  z-index: 9999; 
  min-width: 100%;
  width: 100%; 
  height: 100%; 
  position: fixed; 
  top: 0;
  right: 0;
  /* left: 0; */
}
.ev-chat .bot-popup.fullscreen .chat-body{
  height: calc(100vh - 155px);
}
/* .ev-chat .bot-popup::-webkit-resizer .bot-popup{
  transition: none !important;
}
.ev-chat .bot-popup::-webkit-resizer {
  top: 57px;
  height: 120px;
  font-size: 120px;
  direction: ltr;
  transition: none !important;
    float: right;
    transform: translate(65px, 10px);
} */
.ev-chat .bot-container.show-bot .bot-popup {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
  resize: horizontal;
  overflow: auto;
  direction: rtl;
}

.ev-chat .bot-popup .chat-header {
  display: flex;
  padding: 12px 10px;
  align-items: center;
  background: var(--theme-blue);
  justify-content: space-between;
  direction: ltr;
}

.ev-chat .chat-header .header-info {
  display: flex;
  gap: 10px;
  align-items: center;
}
/* .ev-chat .bot-popup #handle_resize{
  position: absolute;
  left: 0px;
  top: 50%;
  bottom: 0;
  width: 0.25rem;
  cursor: w-resize;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #c2b8b8;
  height: 36px;
  border-style: solid;
} */
/* .ev-chat .header-info svg {
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  padding: 6px;
  fill: #6D4FC2;
  background: #fff;
  border-radius: 50%;
} */
.ev-chat .brand-logo img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    /* background-color: #efefef; */
    background-color: #fff;
    /* border: 1px solid #c6c2c2; */
}
.ev-chat .brand-logo img:first-child{
    margin-bottom: 11px;
}
/* .ev-chat .brand-logo img:last-child{
    margin-bottom: -4px;
    margin-left: -9px;
} */
/* .ev-chat .header-info .brand-logo img{
    background-color: white;
} */
.ev-chat .header-info .logo-text {
  color: #fff;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 0.02rem;
  margin-bottom: 0px;
}
.ev-chat .header-info .logo-text span{
    font-size: 14px;
    color: #efefef;
}
.ev-chat .chat-header button {
  border: none;
  /* height: 40px; */
  /* width: 40px; */
  color: #fff;
  cursor: pointer;
  /* padding-top: 0px; */
  /* margin-right: -3px; */
  font-size: 22px;
  /* border-radius: 50%; */
  background: none;
  transition: 0.2s ease;
  -webkit-text-stroke: 0px white;
  padding: 0;
}
.ev-chat .chat-header button .fa{
  font-size: 18px;
}
.ev-chat .chat-header button:hover {
  /* background: #593bab; */
  color: #efefef;
}

.ev-chat .chat-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* height: 460px; */
  height: 55vh;
  overflow-y: auto;
  margin-bottom: 82px;
  padding: 25px 10px;
  scrollbar-width: thin;
  scrollbar-color: #DDD3F9 transparent;
  direction: ltr;
}
.ev-chat .chat-body .message {
  display: flex;
  /* gap: 11px; */
  gap: 0px 11px;
  /* align-items: center; */
}

/* .ev-chat .chat-body .message svg {
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  padding: 6px;
  fill: #fff;
  align-self: flex-end;
  margin-bottom: 2px;
  background: #6D4FC2;
  border-radius: 50%;
} */
.ev-chat .chat-body .message p{
    margin-bottom: 0px;
}
.ev-chat .chat-body .message .message-text {
  padding: 12px 16px;
  max-width: 75%;
  font-size: 0.95rem;
  word-wrap: break-word;
  white-space: pre-line;
}

.ev-chat .chat-body .message.error .message-text {
  color: #ff0000;
}

.ev-chat .chat-body .bot-message .message-text {
  background: #F6F2FF;
  border-radius: 13px 13px 13px 3px;
}

.ev-chat .chat-body .user-message {
  flex-direction: column;
  align-items: flex-end;
}

.ev-chat .chat-body .user-message .message-text {
  /* color: #fff; */
  /* background: var(--theme-blue); */
  /* background-color: #2d8cef; */
  border-radius: 13px 13px 3px 13px;
  background-color: #c0dfff;
  color: black;
}

.ev-chat .chat-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #f4f7f9;
  /* padding: 15px 22px 20px; */
  padding: 12px 15px 14px;
  direction: ltr;
}

.ev-chat .chat-footer .chat-form {
  display: flex;
  align-items: center;
  position: relative;
  background: #fff;
  /* border-radius: 32px; */
  border-radius: 5px;
  outline: 1px solid #CCCCE5;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.06);
}

.ev-chat .chat-form:focus-within {
  outline: 2px solid var(--theme-blue);
}

.ev-chat .chat-form .message-input {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  font-size: 0.95rem;
  padding: 0 17px;
  background: none;
}

.ev-chat .chat-form #send-message {
  height: 35px;
  width: 35px;
  border: none;
  flex-shrink: 0;
  color: #fff;
  cursor: pointer;
  display: none;
  margin-right: 6px;
  background: var(--theme-blue);
  border-radius: 50%;
  font-size: 1.15rem;
  transition: 0.2s ease;
  padding: 7px;
}
.ev-chat .chat-form #send-message img{
    filter: brightness(0) invert(1);

}
.ev-chat .chat-form .message-input:valid~#send-message {
  display: block;
}
.ev-chat .chat-form #send-message:hover {
  background: #593bab;
}
.ev-chat .chat-time {
    font-size: 10px;
    margin-top: 5px;
}
.ev-chat .chat-body .chat-time {
    width: 100%;
    display: block;
    text-align: right;
    /* color: black; */
    color: #767676;
    margin-top: 0px;
}
/* .bot-toggler-mob{
  display: none;
} */
/* .bot-popup::after {
  content: '';
  background-color: #ccc;
  position: absolute;
  left: 0;
  width: 4px;
  height: 100%;
  cursor: ew-resize;
} */
/* Shake */
@keyframes botBtnShake {
    /* 0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(5deg); }
   95% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); } */
  0% { transform:translate(0,0) }
  80% { transform:translate(0,0) }
  85% { transform:translate(5px,5px) }
  95% { transform:translate(-5px,-5px) }
  100% { transform:translate(0px,0) }
}
/* Responsive media query for mobile screens */
@media (max-width: 768px) {
  /* .bot-toggler-pc{
    display: none;
  }
  .bot-toggler-mob{
    display: block;
  } */
  .ev-chat .bot-toggler-mob .ev-label {
    padding: 6px;
    width: 100%;
    background: var(--theme-blue);
    color: white !important;
   }
  .ev-chat .bot-toggler-mob .unread-badge{
    transform: rotate(90deg);
    right: -15px;
  }
}
@media (max-width: 520px) {
.ev-chat .bot-toggler {
    right: 20px;
    bottom: 20px;
  }
  .ev-chat .bot-popup {
    right: 0;
    bottom: 0;
    /* height: 92%; */
    border-radius: 0;
    width: 100%;
    min-width: 100%;
  }
  .ev-chat .bot-popup .chat-header {
    padding: 12px 15px;
  }
  .ev-chat .chat-body {
    /* height: calc(95% - 55px); */
    height: 68vh;
    /* height: 75vh; */
    padding: 25px 15px;
  }
  .ev-chat .bot-popup.fullscreen .chat-body{
    height: calc(100vh - 165px);
  }
  .ev-chat .chat-footer {
    padding: 10px 15px 15px;
  }
}