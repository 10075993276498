.event-list:not(.page) .inside-article {
    /* padding: 0 0 30px 0; */
    padding: 0 0 40px 0;
}
.event-list .inside-article {
    border-bottom: 1px solid #0F326B;
}
.event-list .generate-columns-container article:not(.featured-column) .post-image {
    float: left;
    text-align: left;
    margin-left: 0;
    margin-right: 30px;
    margin-top: 20px;
}
.resize-featured-image .post-image img {
    width: 220px;
}
.event-list .generate-columns {
    margin-bottom: 50px;
    padding-left: 50px;
}
/* Event details */
.single-post .entry-title {
    font-size: 26px;
    font-weight: 700;
}
.featured-image img {
    width: 500px;
}
.single-post .entry-content {
    margin-top: 45px;
}
.post-image-below-header.post-image-aligned-left .inside-article .featured-image{
    margin-top: 2em;
}
.single-post ol li{
    list-style: decimal;
}
.single-post ul li{
    list-style: disc;
}
.post-image-aligned-left .inside-article .featured-image, .post-image-aligned-left .inside-article .post-image {
    margin-top: 0;
    margin-right: 2em;
    float: left;
    text-align: left;
}
@media only screen and (min-width: 960px){
    .event-list h2.entry-title, 
    .event-list .entry-summary{
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
    .event-list h2.entry-title{
        -webkit-line-clamp: 1;
    }
    .event-list .entry-summary{
        -webkit-line-clamp: 4;
    }
}
@media only screen and (max-width: 960px){
    .event-list .generate-columns-container article:not(.featured-column) .post-image {
        float: none;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        margin-top: 20px;
    }
    .resize-featured-image .post-image img {
        width: 100%;
    }
    .single-post .entry-title {
        font-size: 20px;
        text-align: left;
    }
}
@media (max-width: 767px){
    .generate-columns.mobile-grid-100, .grid-sizer.mobile-grid-100 {
        width: 100%;
    }
    .generate-columns-container .generate-columns, .generate-columns-container>* {
        padding-left: 0;
    }
    .event-list:not(.page) .inside-article{
        padding: 0px;
    }
    /* .event-list .site-main {
        padding: 20px 10px 15px 10px;
    } */
    .post-image-aligned-left .inside-article .featured-image, .post-image-aligned-left .inside-article .post-image {
        margin-right: 0;
    margin-left: 0;
    float: none;
    text-align: center;
    }
}
/* //Gallery */
.gallery-list h2{
    margin-bottom: 20px;
}