.team-sec .team-card h2 {
    /*  background-color: #363b8b;*/
      background-color: var(--theme-blue);
      position: absolute;
      top: calc(100% - 16px);
      left: 0;
      width: 100%;
      padding: 10px 16px;
      color: #fff;
      font-size: 16px;
      line-height: 1.6em;
      margin: 0;
      z-index: 10;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      display: grid;
    /*  box-shadow: 7px 15px 10px -8px rgba(108, 108, 108, 0.66);*/
      box-shadow: 0px 13px 12px -6px rgb(100 97 97 / 66%);
    }
    .team-sec .team-card h2:before {
      /*border-top-color: transparent;
      border-right-color: #101125;
      border-bottom-color: #101125;
      border-left-color: transparent;*/
      content: ' ';
      position: absolute;
      left: 0;
      top: -16px;
      width: 0;
      border: 8px solid;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
    }
    .team-sec .team-card h2:before {
        border-top-color: transparent;
        border-right-color: #101125;
        border-bottom-color: #101125;
        border-left-color: transparent;
    }
    .team-sec .team-card .img-wrap {
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
    /*  background: #fff;*/
    /*  background: #87ceeb;*/
    /*  padding-left: 16px;*/
      box-shadow: 0 2px 10px -2px rgba(108, 108, 108, 0.66);
    /*  background: linear-gradient(95.2deg, rgb(173, 252, 234) 26.8%, rgb(192, 229, 246) 64%);*/
      background: linear-gradient(89.2deg, rgb(191, 241, 236) 22.3%, rgb(109, 192, 236) 84.1%);
    }
    .team-sec .team-card .img-wrapper{
      position: absolute;
      right: 0;
      top: 0;
      bottom: -25px;
      overflow: hidden;
      left: 16px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
    }
    .team-sec .team-card .team-detail{
      position: relative;
      height: 0;
      padding-bottom: calc(100% - 16px);
      margin-bottom: 6.6em;
    }
    .team-sec .team-card .team-detail h2 strong,
    .team-sec .team-card .team-detail h2 .position{
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    .team-sec .team-card .team-detail h2 strong{
      -webkit-line-clamp: 1;
    /*  color: white;*/
      color: #00ffff;
      font-size: 17px;
    }
    .team-sec .team-card .team-detail h2 .position{
      -webkit-line-clamp: 1;
    /*  color: #00ffff;*/
      color: white;
      font-size: 14px;
      font-weight: 400;
    }
    .team-sec .team-card .img-wrapper img{
      height: 100%;
      /* object-fit: cover; */
      width: 100%;
    }
    .team-sec .no-fellow{
      text-align: center;
    }
     .team-sec .no-fellow img{
      height: 75px;
    }
     .team-sec .no-fellow .head{
      font-size: 18px;
      font-weight: 500;
    }
     .team-sec .no-fellow .sub-title{
      font-size: 16px;
    }
    /*body{
      background: #f1f3f6;
    }*/
    /*--------------------------------------------------------------
    # Team Detail
    --------------------------------------------------------------*/
    .team-detail .loader{
      width: 20px;
      height: 20px;
    }
    .team-detail .portfolio-img{
      max-width: 115px;
      max-height: 115px;
    }
    /* .team-detail img, */
    .team-detail embed,
    .team-detail object{
      width: 115px;
      height: 115px;
    }
    .team-detail h2 a,
    .team-detail h2 {
      color: var(--theme-blue);
      font-size: 19px;
    }
    .topic-detail h2{
      padding: 10px 10px !important;
    }
    .topic-detail h2 .icon a{
      font-size: 14px;
      color: white;
    }
    .topic-sec .heading-subtext:hover,
    .topic-sec .heading-subtext:focus{
      background: transparent;
      color: red;
      box-shadow: none;
    }
    .topic-detail h2 .icon i{
      font-size: 12px;
    }
    .team-detail h3{
      /* font-size: 15px; */
      font-size: 19px;
      /* color: var(--blue); */
      color: var(--theme-blue);
      margin-bottom: 1px;
      font-weight: 600;
    }
    .team-detail ol li{
      list-style: auto;
      /* font-size: 14px; */
    /*  font-weight: 500;*/
    }
    .team-detail .card{
      /* box-shadow: 0px 13px 12px -6px rgb(100 97 97 / 66%); */
      box-shadow: 0 0 16px #00000014;
      border-radius: 15px;
    }
    .team-detail .position{
      margin-bottom: 0px;
      font-size: 17px;
      color: black;
      margin-top: 3px;
    }
    .team-detail .country,
    .stu-popp .stu-info .country{
      /* font-size: 18px; */
      margin-top: 12px;
      color: gray;
      font-weight: bold;
      /* font-style: italic; */
      text-transform: capitalize;
    }
    .team-detail .logo-input{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0;
      margin: 0;
      cursor: pointer;
      filter: alpha(opacity=0);
      opacity: 0;
    }
    .team-detail .upload-img{
      position: relative;
      display: table;
    }
    .team-detail .bg-lgt-green{
      background-color: #8bc34a !important;
    }
    .team-detail .drop-dot .dropdown-toggle::after {
      display: none;
    }
    .team-detail .drop-dot .dropdown-toggle i {
      color: black;
    }
    /* .team-detail .drop-dot button:focus i{
      color: white;
      background-color: #00ffff;
    } */
    .team-detail .drop-dot button{
      background-color: transparent;
      border: 0;
    }
    .team-detail .fa-check-circle.com{
      /* color: #00ff00; */
      color: #0eef0e;
    }
    .profile-add-form ol li{
      margin-bottom: 15px;
    }
    .profile-add-form thead{
      vertical-align: revert;
    }
    .profile-add-form em{
      color: red;
    }
    .profile-add-form select option{
      color: black;
      /* color: #333333; */
    }
    /* .css-1nmdiq5-menu{
      z-index: 99999 !important;
    }
    .my-custom-react-select__menu {
      z-index: 99999;

    } */
    /* .my-custom-react-select__control {
      border-width: 1px !important;
      border-color: #cbd5e0 !important;
      padding-top: 0.16rem;
      padding-bottom: 0.16rem;
    }
    
    .my-custom-react-select__control--is-focused {
      border-color: #746df7 !important;
      box-shadow: none !important;
    } */
    
    /* .my-custom-react-select__indicator-separator {
       display: none;
    } */
    .team-detail .toggle-button input{
      height: 25px;
      width: 60px;
    }
    .team-detail .toggle-button label{
      margin-left: 5px;
      cursor: pointer;
    }
    .team-detail .toggle-button input{
      cursor: pointer;
    }
    @media only screen and (max-width: 450px){
      /* .team-detail img{
        width: 100px;
        height: auto;
      } */
      .team-detail .portfolio-img{
        max-height: 100px;
        max-width: 100px;
      }
    }
    @media only screen and (max-width: 600px){
      .team-detail h2 a{
        font-size: 18px;
      }
      .team-detail .position{
        font-size: 13px;
      }
    }
    .team-detail ul li{
      list-style: disc;
    }
    .team-detail ul.alpha li{
      list-style: lower-alpha;
    }
    .team-detail ol.number li{
      font-size: 17px !important;
    }
/*--------------------------------------------------------------
# Portfolio Detail
--------------------------------------------------------------*/
.team-detail, .team-detail h2, .team-detail h3{
  font-family: 'Roboto', sans-serif;
}
.team-detail, .team-detail h3 span, .team-detail ol li{
  font-weight: 400;
  font-size: 16px;
  color: black;
}
.last-col{
  width: calc(100vw - 95vw);
}
/*--------------------------------------------------------------
# Portfolio Edit
--------------------------------------------------------------*/
.portfolio-edit em{
  color: red;
  font-weight: bold;
}
.portfolio-edit .t-light{
  color: #0009;
  font-size: 14px;
}
.portfolio-edit .t-light a{
  width: calc(100% - 25px);
}
.portfolio-edit .btn-edit{
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  /* display: contents; */
  display: inline-flex;
  margin-left: 2px;
}
.portfolio-edit .btn-edit:hover{
  color: white;
}
.portfolio-edit .breadcrumb-link img{
  width: 35px;
  height: 35px;
}
.portfolio-edit .port-scroll{
  max-height: 350px;
  overflow-y: auto;
}
/*--------------------------------------------------------------
# Student Section
--------------------------------------------------------------*/
.student-sec .img-container{
  position: relative;
  height: 0;
  padding-bottom: 75%;
  overflow: hidden;
  border-radius: 8px;
}
.student-sec .image-wrap{
  background-color: #FEFEFD;
}
.student-sec .stats-container{
  display: flex;
}
.student-sec .stats-container,
.student-sec .stats-container button{
  /* display: flex; */
  color: #9e9ea7;
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px
}
.student-sec-lg .stats-container{
  font-size: 16px;
}
.student-sec .stats-container button,
.student-sec .img-container .share-content ul button{
  padding: 0px;
}
.student-sec .stats-container button:hover{
  background-color: transparent;
}
.student-sec button:focus{
  box-shadow: none;
  background-color: transparent;
}
.student-sec .stats-container button:hover svg,
.student-sec .img-container .share-content button:hover svg,
.student-sec .stats-container button.active svg,
.student-sec .img-container .share-content button.active svg
{
  color: var(--theme-blue);
  stroke: var(--theme-blue);
}
.student-sec .img-container .share-content button:hover svg path,
.student-sec .img-container .share-content button.active svg path{
  color: var(--theme-blue);
  stroke: var(--theme-blue);
  fill: currentColor;
}
.student-sec .stats-container .stats-icon{
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 8px;
}
.student-sec .stats-container .stats-icon .btn:disabled{
   opacity: 1;
}
.student-sec .stats-container .icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #9e9ea7;
  fill: currentColor;
}
.student-sec-lg .stats-container .icon{
  width: 20px;
  height: 20px;
}
.student-sec .stats-container .stats-count{
  color: #3d3d4e;
  font-weight: 600;
}
/* .fill-current, .fill-current svg {
  
} */
.student-sec .details-container{
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 8px;
  align-items: flex-start;
}
.student-sec .details-container .display-name{
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -ms-flex: 1;
  flex: 1; */
  color: #0d0c22;
}
.student-sec .details-container .position{
  color: #191919;
  /* flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 1px;
  white-space: nowrap; */
  font-size: 13px;
  font-weight: 400;
}
.student-sec .details-container .user-detail,
.student-sec .details-container .user-detail a,
.student-sec .img-container .share-content{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-width: 0;
}
.student-sec .details-container .position,
.student-sec .details-container .display-name{
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.student-sec .img-container .share-overlay{
  display: -ms-flexbox;
  display: flex;
  z-index: 2;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 20px;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
  border-radius: 8px;
  opacity: 0;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(62%, rgba(0, 0, 0, 0)), color-stop(63.94%, rgba(0, 0, 0, 0.00345888)), color-stop(65.89%, rgba(0, 0, 0, 0.014204)), color-stop(67.83%, rgba(0, 0, 0, 0.0326639)), color-stop(69.78%, rgba(0, 0, 0, 0.0589645)), color-stop(71.72%, rgba(0, 0, 0, 0.0927099)), color-stop(73.67%, rgba(0, 0, 0, 0.132754)), color-stop(75.61%, rgba(0, 0, 0, 0.177076)), color-stop(77.56%, rgba(0, 0, 0, 0.222924)), color-stop(79.5%, rgba(0, 0, 0, 0.267246)), color-stop(81.44%, rgba(0, 0, 0, 0.30729)), color-stop(83.39%, rgba(0, 0, 0, 0.341035)), color-stop(85.33%, rgba(0, 0, 0, 0.367336)), color-stop(87.28%, rgba(0, 0, 0, 0.385796)), color-stop(89.22%, rgba(0, 0, 0, 0.396541)), color-stop(91.17%, rgba(0, 0, 0, 0.4)));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 62%, rgba(0, 0, 0, 0.00345888) 63.94%, rgba(0, 0, 0, 0.014204) 65.89%, rgba(0, 0, 0, 0.0326639) 67.83%, rgba(0, 0, 0, 0.0589645) 69.78%, rgba(0, 0, 0, 0.0927099) 71.72%, rgba(0, 0, 0, 0.132754) 73.67%, rgba(0, 0, 0, 0.177076) 75.61%, rgba(0, 0, 0, 0.222924) 77.56%, rgba(0, 0, 0, 0.267246) 79.5%, rgba(0, 0, 0, 0.30729) 81.44%, rgba(0, 0, 0, 0.341035) 83.39%, rgba(0, 0, 0, 0.367336) 85.33%, rgba(0, 0, 0, 0.385796) 87.28%, rgba(0, 0, 0, 0.396541) 89.22%, rgba(0, 0, 0, 0.4) 91.17%);
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
.img-container:hover .share-overlay,
.img-container .mod-share-overlay{
  opacity: 1 !important;
}
.img-container .mod-share-overlay{
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 81%) !important;
}
.student-sec .img-container .share-content{
  -ms-flex: 1;
  flex: 1;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.student-sec .img-container .share-content ul{
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 0px;
}
.student-sec .img-container .share-content li{
  margin-left: 12px;
}
.student-sec .img-container .share-content li button{
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: white;
  color: white;
  pointer-events: auto;
}
/* .student-sec .img-container .share-content .share-btn svg{
  stroke: #212529;
  fill: currentColor;
  color: #212529;
  fill: none;
  stroke-width: 3;
  stroke: red;
} */
.student-sec .img-container .share-content button svg path{
  stroke: #212529;
}
.popp{
  display: none;
  /* top: 700px; */
    position: absolute;
    z-index: 99;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 400px;
    margin-bottom: 10px;
    margin-left: -5px;
    padding: 20px 20px 15px;
    border-radius: 12px;
    background-color: #fff;
    -webkit-box-shadow: 0px 20px 48px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 20px 48px rgba(0, 0, 0, 0.2);
}
.popp-content{
  display: flex;
  margin-bottom: 16px;
  align-items: center;
}
.popp:after{
  top: 0;
  bottom: auto;
  left: 30px;
  -webkit-transform: rotate(-225deg);
  -ms-transform: rotate(-225deg);
  transform: rotate(-225deg);
  content: '';
    position: absolute;
    bottom: -14px;
    left: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 0;
    height: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #fff #fff;
    -webkit-box-shadow: -3px 2px 2px rgba(0, 0, 0, 0.05);
    box-shadow: -3px 2px 2px rgba(0, 0, 0, 0.05);
    top: 0;
    bottom: auto;
    left: 30px;
    -webkit-transform: rotate(-225deg);
    -ms-transform: rotate(-225deg);
    transform: rotate(-225deg);
}
/* div.class:not(:has(div.class:hover)):hover */
/* .display-name:hover #popp,
.display-name:hover > #main:has(#popp)
div:has(+ div > .display-name) > div{
  background-color: green;
} */
/* .popp:hover{
  display: block !important;
} */
/* .user-detail .display-name:not(:hover)  */
/* .popp:not(:hover){
  display: none !important;
} */
/* .popp:not(:hover),
.user-detail .display-name:not(:focus) .popp{
  display: none !important;
} */
/* .popp:focus,
.user-detail a:focus .popp{
  display: block;
} */
/* .user-detail a:hover ~ .popp,
 .popp:hover {
  display: block; 
} */

.stu-popp{
  margin-bottom: 16px;
  display: flex;
}
.stu-popp .stu-img{
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
}
.stu-popp .stu-img img{
  width: 100%;
  border-radius: 50%;
}
.stu-popp .stu-info{
  margin-left: 16px;

}
/* @media screen and (min-width: 768px){
  .student-sec  {
    top: -40px;
    position: relative;
  }
} */